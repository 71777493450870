import React from "react";
import styleSelf from "./bio.module.css";
import Img from "gatsby-image";
import { Link } from "gatsby";

export default ({ node }) => {
  if (node.frontmatter.status == "hiring" || node.frontmatter.status == "pending") {
    return (
      <div
        className={
          styleSelf.fullContainer + " " + styleSelf[node.frontmatter.status]
        }
      >
        <div className={styleSelf.fullHeader}>
          <h3>{node.frontmatter.name}</h3>
        </div>
        <div className={styleSelf.fullDesc}>
          <span>{node.frontmatter.summary}</span>

         {node.frontmatter.status == "hiring" && <h4>
            📝{" "}
            <Link to={node.frontmatter.path}>Click here to find out more!</Link>
          </h4>}

        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          styleSelf.bioContainer + " " + styleSelf[node.frontmatter.status]
        }
      >
        <div className={styleSelf.bioStats}>
          <div className={styleSelf.bioImageContainer}>
            <Img className={styleSelf.bioImage} fluid={node.frontmatter.photo.childImageSharp.fluid} />           
          </div>
        </div>

        <div className={styleSelf.bioContents}>
          <font color={node.frontmatter.textcolor}>
          <h2>{node.frontmatter.name}</h2>
          <h5>{node.frontmatter.title} </h5>
          </font>
          <br/>
        </div>
      </div>
    );
  }
};
